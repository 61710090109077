import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
//import TextField from '@material-ui/core/TextField';
import Tableau from './Tableau';
import ParamPage from'./ParamPage';
import logo from './Apidae-Form.svg'; 
import bg from './apidae-tet.png';

const useStyles = theme => ({
   
  root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing.unit,
      color:'#645E51',
    },
    bar:{
      //background:'linear-gradient(60deg, #0000 40%, #3f51b5 80%)',
      backgroundImage:`url(${bg})`,
    },
    title: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit*2,
    },
    dense: {
      marginTop: theme.spacing.unit,
    },
    menu: {
      width: 200,
    },
    formControl: {
      margin: theme.spacing.unit*3,
    },
    group: {
      margin: theme.spacing.unit*3,
    },
  });

  class ButtonAppBar extends React.Component {
    constructor(props){
      super(props);
      this.state ={
        data: [],
        error: null,
        isLoaded: false,
        configOK : false,
        contentPage: null,
      }
      //console.log(this.props);
      this.paramOT();
    }
    
    /*
    componentDidMount= () => {
      this.paramOT();
    }
  */
    paramOT = () =>{
      let urlAppel=process.env.REACT_APP_APIURL+"/paramsOT?OT="+window.user.OT;
      //console.log(urlAppel);
      fetch(urlAppel,{ 
        method: 'get', 
        headers: new Headers({
            'Authorization': 'Bearer '+ window.user.jeton
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(result.ID_Projet);
            if (result.ID_Projet>0 && !result.cle_API!=='' && 
            (result.Critere_adherent !=='' || result.Selection >0 || result.Territoire >0)){
              let page='ParamPage';
              if(result.nbois > 0 ) page='Tableau';
              
              this.setState({
                isLoaded: true,
                data: result,
                configOK:true,
                contentPage : page
              })
              //console.log('OK',this.state.data)
            }
            else{
              this.setState({
                isLoaded: true,
                data: result, 
                contentPage :'ParamPage'
              });
              //console.log('KO',this.state.data)      
            }
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
          
        )
    }

    switchConfig = () =>{
      
      if(!this.state.configOK) {
        this.paramOT();
      }
    }


    content= ()=>{
      //console.log(this.state);
      if (this.state.error) {
        return <div>Error: {this.state.error.message}</div>;
      } else if (!this.state.isLoaded & window.user.OT!=='') {
        return <div>Loading...</div>;
      } else if (!this.state.data) {
        return <div>Error: Merci de contacter votre Animateur pour le paramétrage initial</div>;
      } else if(!this.state.configOK){
        //this.setState({contentPage :'Tableau'});
      return <ParamPage data={this.state.data} switchConfig={this.switchConfig}/>;
      } else if (this.state.contentPage!==null){
          switch (this.state.contentPage){
            case 'Tableau' :
              return <Tableau />;
            case 'ParamPage' :
              return <ParamPage data={this.state.data} switchConfig={this.switchConfig} />
            default:
              return null;
          }
      } else {
          //this.setState({contentPage :'ParamPage'});
        return <ParamPage data={this.state.data} />
      }

    }
    handleClick= (event) => {
      //console.log('clik !');
      switch (this.state.contentPage){
        case 'Tableau' :
          this.setState({contentPage :'ParamPage'});
          break;
        case 'ParamPage' :
          this.setState({contentPage :'Tableau'});
          break;
        default:
          return null;
      }
    }

    render(){
    const { classes } = this.props;
    return (
      
      <div className={classes.root}>
        <AppBar position="static" className={classes.bar}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={event => this.handleClick(event)}>
              <MenuIcon />
            </IconButton>
            
            <img src={logo} alt="Apidae Form" height="60" />
            <Typography variant="h6" className={classes.title}>
              
            </Typography>
            <Button color="inherit" onClick={this.props.logout}>Logout</Button>
          </Toolbar>
        </AppBar>
          { this.content()}
      </div>
    );

    }

  }


export default withStyles(useStyles)(ButtonAppBar);