import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';


const useStyles = theme => ({
   
  root: {
      flexGrow: 1,
      color:'#645E51',
    },
    menuButton: {
      marginRight: theme.spacing.unit,
    },
    title: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit*2,
      width: 300,
    },
    dense: {
      marginTop: theme.spacing.unit,
    },
    menu: {
      width: 200,
    },
    formControl: {
      margin: theme.spacing.unit*3,
    },
    group: {
      margin: theme.spacing.unit*3,
    },
    colorPrimary:{
      //backgroundColor:'#645E51',
      color:'#168AC8',
      "&:hover": {
        //backgroundColor: "#645E51"
      },
    },
    colorSecondary:{
      //backgroundColor:'#F46754',
      color:'#F46754',
    }
  });

  class ParamPage extends React.Component {

    constructor(props){
      super(props);
      this.state ={
        data:this.props.data,
        saisieValide:true,
        errorMessage:'',
        radioButton:this.props.data.Critere_adherent!==''?'Critere_adherent':'Selection',
        importEncours:false,
        importTraite:'',
        reqError:{ projet:false,
                   API : false,
                   email:false }
      }
      //console.log(this.props);
    };

    handleInputChange(event) {
      let tempData=this.state.data;
      const target = event.target;
      const value = target.value;
      const name = target.id;
      //console.log(name,value);
      tempData[name]= value;

      this.setState({
        data:tempData,
        saisieValide:true,
        errorMessage:''
      });
    }
    displayMessage(){
      if(this.state.errorMessage!==''){
        return(
          <TextField
          error={this.state.saisieValide}
          id="outlined-error"
          label="Status de la mise a jour OT"
          value={this.state.errorMessage}
          className={this.props.classes.textField}
          margin="normal"
          variant="outlined"
        />
        );
      }


    }
    displayMessageImport(){
      //console.log(this.state.importEncours);
      if(this.state.importTraite!==''){
        return(
          <TextField
          id="Import-traité"
          label="Imports traités"
          value={this.state.importTraite}
          className={this.props.classes.textField}
          margin="normal"
          variant="outlined"
        />
        );
      }else if(this.state.importEncours===true){
        
        return(
          <TextField
          id="Import-traité"
          label="Imports en cours"
          value="veuillez patienter"
          className={this.props.classes.textField}
          margin="normal"
          variant="outlined"
        />
        );
      }
     }

    testEmail(email){
      
      let emailtab=email.split(';');
      let ok=true;
      emailtab.forEach(element => {
        if(!/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(element))
        {
          //console.log('email ok');
          ok=false;
        } 
      });

        return ok;
    }

   
    onSubmit(event){
      event.preventDefault();
      const data=this.state.data;
      const reqError=this.state.reqError;
      let saisieTemp=this.state.saisieValide;
      let message=this.state.errorMessage;
      let errors=0;

      if ( !this.testEmail(data['email'])){
        errors=errors+1;
        message='le format d\'email est incorrect';
        reqError.email=true;
      } else {
        reqError.email=false;
      }
      if ( data['ID_Projet'] < 1){
        errors=errors+1;
        message='l\'identifiant Projet est invalide';
        reqError.projet=true;
      } else{
        reqError.projet=false;
      }
      if( data['cle_API'] === ""){
        errors=errors+1;
        message='la clé API n\'est pas renseignée';
        reqError.API=true;
      } else {
        reqError.API=false;
      }

      if( errors === 1){
        this.setState({
          errorMessage:message,
          reqError:reqError
        });

      } else if (errors > 1){
        this.setState({
          errorMessage:"Veuillez corriger les erreurs de saisie",
          reqError:reqError
        });
      }else {
        //console.log(data);
      let complementURL =JSON.stringify(data);
        complementURL=complementURL.replace(new RegExp('{', 'g'),"");
        complementURL=complementURL.replace(new RegExp('}', 'g'),"");
        complementURL=complementURL.replace(new RegExp(':', 'g'),"=");
        complementURL=complementURL.replace(new RegExp(',', 'g'),"&");
        complementURL=complementURL.replace(new RegExp('"', 'g'),"");
        
        if(saisieTemp){
          complementURL+='&test';
        };

        //console.log(process.env.REACT_APP_APIURL);
        //console.log(complementURL);
        fetch(process.env.REACT_APP_APIURL+'/putOt?'+complementURL,{ 
          method: 'get', 
          headers: new Headers({
              'Authorization': 'Bearer '+ window.user.jeton
          })
        })
        .then(res => res.json())
        .then(
          (res) => {
            let result=res.result;
            if(result==='OK' || result==='OT mis a jour'){
              //console.log(!this.state.saisieValide);
              if(!saisieTemp){
                //console.log('switch');
                this.props.switchConfig();
                saisieTemp=false;
                message=result;
              } else {
                saisieTemp=false;
                message=result;
              } 
              this.setState({
                saisieValide:saisieTemp,
                errorMessage:message,
              });
            }else{
              reqError.API=true;
              reqError.projet=true;
              this.setState({
                errorMessage:'Vérifiez l\'ID Projet et la clé API',
                reqError:reqError
              });
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          }
        )
      }
    }

    
    handleChange= (event) => {
      this.setState({
        radioButton:event.target.value
      })
    }

    onImport = (event) =>{
      this.setState({
        importEncours:true
      });
      fetch(process.env.REACT_APP_APIURL+'/fillDb?method='+this.state.radioButton.toLowerCase()+'&OT='+this.state.data.ID,{ 
        method: 'get',
        timeout:50000,
        headers: new Headers({
            'Authorization': 'Bearer '+ window.user.jeton
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          this.setState({
            importTraite:result,
            importEncours:false
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //console.log(error);
          this.setState({
            importEncours:false
          })
        }
      )
    }

    render(){
    const { classes } = this.props;
    const data=this.state.data;
    const reqError=this.state.reqError;
    //console.log(reqError);
    const saisieValide =this.state.saisieValide;
    return (
      <div>
        <Grid container>
          <Grid item xs={6}>
            <form className={classes.container} noValidate autoComplete="off" onSubmit={event => this.onSubmit(event)}>
            <Grid item xs={3}> </Grid>
            <Grid item xs={9}>
              <FormLabel component="legend" > Configuration Apidae pour l'Import </FormLabel>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                id="ID"
                label="Identifiant Apidae du Membre"
                className={classes.textField}
                value={data.ID}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Nom"
                label="Nom du membre"
                defaultValue={data.Nom}
                className={classes.textField}
                onChange={event => this.handleInputChange(event)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={reqError.projet}
                required
                id="ID_Projet"
                label="Identifiant Projet"
                type="number"
                defaultValue={data.ID_Projet}
                className={classes.textField}
                onChange={event => this.handleInputChange(event)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={reqError.API}
                required
                id="cle_API"
                label="clé API Projet"
                defaultValue={data.cle_API}
                className={classes.textField}
                onChange={event => this.handleInputChange(event)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Critere_adherent"
                label="Critere interne pour l'import"
                defaultValue={data.Critere_adherent}
                className={classes.textField}
                onChange={event => this.handleInputChange(event)}
                margin="normal"
                variant="outlined"
                helperText="insérez ici vos critères internes séparés par des ; "
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="Selection"
                label="Selection pour l'import"
                defaultValue={data.Selection}
                className={classes.textField}
                onChange={event => this.handleInputChange(event)}
                margin="normal"
                variant="outlined"
              />
              
              { /*
              <TextField
                id="Territoire"
                label="Territoire pour l'import"
                defaultValue={data.Territoire}
                type="number"
                className={classes.textField}
                onChange={event => this.handleInputChange(event)}
                margin="normal"
                variant="outlined"
              />

              */}
        
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText="le ou les emails sont utilisé pour vous envoyer une notification à la validation d'un formulaire. Séparez les par un ; "
                error={reqError.email}
                required
                id="email"
                label="email(s) notifié(s)"
                defaultValue={data.email}
                type="email"
                className={classes.textField}
                onChange={event => this.handleInputChange(event)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>  </Grid>
            <Grid item xs={6}>             
        
              <Button disabled={!saisieValide} className={classes.colorPrimary} onClick={event => this.onSubmit(event)}> Vérifier la saisie </Button>
              <Button type="submit" disabled={saisieValide} className={classes.colorSecondary}> Valider </Button>
        
            </Grid>
            </form>
            {this.displayMessage()}
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" >Importer les objets </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender2"
                className={classes.group}
                value={this.state.radioButton}
                onChange={event => this.handleChange(event)}
              >
                <FormControlLabel
                  value="Critere_adherent"
                  disabled={data.Critere_adherent!==''?false:true}
                  control={<Radio className={classes.colorPrimary} color="default" />}
                  label="Critere interne"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="Selection"
                  disabled={data.Selection>0?false:true}
                  control={<Radio className={classes.colorPrimary} color="default" />}
                  label="Selection"
                  labelPlacement="start"
                />
                {/*
                <FormControlLabel
                  value="Territoire"
                  disabled={data.Territoire>0?false:true}
                  control={<Radio color="primary" />}
                  label="Territoire"
                  labelPlacement="start"
                />
                
                  <FormControlLabel
                  value="propriete"
                  control={<Radio color="primary" />}
                  label="propriétaire"
                  labelPlacement="start"
                />
                */}
              </RadioGroup>       
              <Button onClick={event => this.onImport(event)} className={classes.colorPrimary}> Importer </Button>
              {this.displayMessageImport()}
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
    }
  }

  export default withStyles(useStyles)(ParamPage);