import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import fetchWithTimeout from './custom-fetch.js';

function desc(a, b, orderBy) {
  
  if(a[orderBy] !==null && (b[orderBy] ===null || b[orderBy]===undefined)) return -1;
  if(b[orderBy] !==null && (a[orderBy] ===null || a[orderBy]===undefined)) return 1;
  /*
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
  */
  // test use of localcompare function 
  //console.log(b[orderBy]);
  //console.log(a[orderBy]);
  return b[orderBy].toString().localeCompare(a[orderBy].toString(), 'en', { sensitivity: 'base' });
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
   /* if(a[0]['Statut'] !==null ) 
    {console.log (a[0]['Statut']);
      console.log(b[0]['Statut']);
      console.log(order);
    }*/
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'ID', align: 'left', disablePadding: true, label: 'Identifiant',classes:'classes.colIDApidae' },
  { id: 'Type', align: 'left', disablePadding: false, label: 'Type' },
  { id: 'Nom', align: 'left', disablePadding: false, label: 'Nom' },
  { id: 'Commune', align: 'left', disablePadding: true, label: 'Commune' },
  { id: 'Proprietaire_Apidae', align: 'left', disablePadding: false, label: 'Propriétaire de la Fiche' },
  { id: 'Statut_Apidae', align: 'center', disablePadding: true, label: 'Statut Apidae' },
  { id: 'Statut', align: 'center', disablePadding: true, label: 'Statut Q' },
  { id: 'Expire', align: 'right', disablePadding: false, label: 'Date Expiration' },
  { id: 'Date_Import', align: 'center', disablePadding: true, label: 'Date MAJ' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              className={classes.checkbox}
              classes={{checked:classes.checked}}
              color='default'
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={row.classes}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
                { row.id === 'Nom' || row.id==='Type' || row.id==='ID' || row.id==='Statut' ?
                (
                  <TextField
                    placeholder={"filtrer sur le " + row.id}
                    //value={this.state.filtre}
                    className ={classes.monChampTexte}
                    defaultValue=''
                    onChange={e => this.props.filtrer(e.target.value,row.id)}
                  />
                ): (
                  <div/>
                ) }
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    color:'#645E51',
    //overflowX: 'auto',
  },
  table: {
    minWidth: 1020,
    //width: "auto",
    //tableLayout: 'auto',
    //display:'flex'
  },
  checkbox:{
    color:'#168AC8',
    },
  checked:{
      color:'#F46754',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  colIDApidae :{
    width:'2rem',
  },
  monChampTexte:{
    padding:'none',
    '& input':{
      padding:0,
    }
  },
  mycircle:{
    display: 'flex',
    marginLeft:'60px',
  },
  colorPrimary:{
    //backgroundColor:'#645E51',
    color:'#168AC8',
    "&:hover": {
      //backgroundColor: "#645E51"
    },
  },
  colorSecondary:{
    //backgroundColor:'#F46754',
    color:'#F46754',
  }
});

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(styles)(EnhancedTableHead);

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    color:'#645E51',
    //display:'flex'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          //color: theme.palette.secondary.main,
          //backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          color: '#F46754',
          backgroundColor: lighten('#f68576', 0.85),
        }
      : {
          //color: theme.palette.text.primary,
          //backgroundColor: theme.palette.secondary.dark,
          color: '#168AC8',
          backgroundColor: '#aa483a',
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    //color: theme.palette.text.secondary,
    color: '#F46754',
  },
  title: {
    flex: '0 0 auto',
  },
  colorPrimary:{
    //backgroundColor:'#645E51',
    color:'#168AC8',
    "&:hover": {
      //backgroundColor: "#645E51"
    },
  },
  colorSecondary:{
    //backgroundColor:'#F46754',
    color:'#F46754',
  }
});

class EnhancedTableToolbar extends React.Component {
  state ={
    anchorEl:null,
    method:this.props.method,
    openFormGen:false,
    dateExpire:'',
    dateError:false,
    openFormDel:false
  };
  
  handleClick = (event) => {
    this.setState( {anchorEl:event.currentTarget});
  }

  handleForm = (event) => {
    //this.props.formGen();
    this.setState({openFormGen:true})
  }

  handleCancel =(event) => {
    this.setState({openFormGen:false,openFormDel:false})
  }

  handleGen =(event)=>{
    if(!this.state.dateError){
      this.setState({openFormGen:false});
      this.props.formGen(this.state.dateExpire);

    }
     
  }

  handleUpdate = (event) => {
    this.props.formUpdate();
  }

  handleDel = (event) => {
    this.setState({openFormDel:true})
  }
  handleDelConfirm =(event) =>{
    this.setState({openFormDel:false})
    this.props.DelOIs();
  }

  handleClose = () => {
    //console.log(this.state.method);
    //console.log(this.state.method.length!=0);
    if(this.state.method.length!==0){
      this.setState( {anchorEl:null});
      this.props.methodChange(this.state.method);
    }

  }

  changeDateExpire=(event)=>{
    let today=new Date();
    let month=today.getMonth()+1;
    let day=today.getDate();
    if(month.toString().length < 2){
      month='0'+month;
    }
    if(day.toString().length < 2){
      day='0'+day;
    }
    let todayFormat = today.getFullYear()+'-'+month+'-'+day;
    //console.log(event.currentTarget.value);
    //console.log(todayFormat);
    //console.log(event.currentTarget.value>todayFormat);
    if(event.currentTarget.value>todayFormat){
             this.setState({dateExpire:event.currentTarget.value,
                      dateError:false});
    }
    else{
      this.setState({dateError:true});
    }
  }

  isChecked = (methodName,tabl) =>{
    const pos = tabl.indexOf(methodName);
    if(pos >=0 ){return true}
    else { return false}
  }
  handleChangeSwitch = (methodName) => {
    let method = this.state.method;
    const selectedIndex = method.indexOf(methodName);

    if (selectedIndex === -1) {
      method=method.concat([methodName]);
    } else {
      method.splice(selectedIndex, 1);
    }
       this.setState({method: method});
  }
  render(){
    const { numSelected, Selected, classes } = this.props;
    //console.log(this.state.method);
    //const anchorEl= this.state.anchorEl;
    const openFormGen =this.state.openFormGen;
    const openFormDel =this.state.openFormDel;
    return (
    
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} Selectionné
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle" color="inherit">
            Liste des Objets Touristiques
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Grid container direction="row">
          <Grid item xs>
          <Button className={classes.colorSecondary} onClick={event =>this.handleForm()}>
              Générer les formulaires
          </Button>
          </Grid>
          <Grid item xs>
          <Button className={classes.colorPrimary} onClick={event =>window.open(process.env.REACT_APP_APIURL+'/formExp?&OT='+window.user.OT+'&OIs=['+Selected.toString()+']')}>
              Exporter les formulaires
          </Button>
          </Grid>
          <Grid item xs>
          <Button className={classes.colorSecondary} onClick={event =>this.handleUpdate()}>
              Passer les Formulaires a l'état suivant
          </Button>
          </Grid>
          <Grid item xs>
          <Tooltip title="Delete"onClick={event =>this.handleDel()}>
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          </Grid>
          <Dialog open={openFormGen} onClose={event =>this.handleCancel()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Generer les formulaires</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Veuillez choisir la date d'expiration des formulaires, si vous ne choisissez pas de date, les formulaires expireront au bout de 2 mois
                  </DialogContentText>
                  <TextField
                    id="expiration"
                    label="Date Expiration"
                    type="date"
                    defaultValue={this.state.dateExpire}
                    error={this.state.dateError}
                    onChange={event=>this.changeDateExpire(event)}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={event =>this.handleCancel()} className={classes.colorSecondary}>
                    Annuler
                  </Button>
                  <Button onClick={event =>this.handleGen()} className={classes.colorPrimary}>
                    Générer
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={openFormDel} onClose={event =>this.handleCancel()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Supprimer les formulaires</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Vous vous apprétez à supprimer les formulaires des Ois sélectionnés, ils ne seront plus accéssibles pour vos socio pros, êtes vous sûr?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={event =>this.handleCancel()} className={classes.colorSecondary}>
                    Annuler
                  </Button>
                  <Button onClick={event =>this.handleDelConfirm()} className={classes.colorPrimary}>
                    Supprimer
                  </Button>
                </DialogActions>
              </Dialog>
          </Grid>

        ) : (
          
          <div>
          <Grid container direction="row">
          <Grid item xs>
          <Button className={classes.colorPrimary} onClick={event =>window.open(process.env.REACT_APP_APIURL+'/formExp?&OT='+window.user.OT)}>
              Exporter les formulaires
          </Button>
          </Grid>{ /*
          <Grid item xs>  
          <Tooltip title="Filtrer les résultats">
            <IconButton id="Filtre_resultat" aria-label="Filter" onClick={event => this.handleClick(event)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          </Grid>*/}
          </Grid> { /*         
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} autoFocus={true} >
            <FormControlLabel control={<Switch checked={this.isChecked("adherent",this.state.method)} value="adhérent" onChange={event => this.handleChangeSwitch ("adherent") }/>} label="adherent" />
            <FormControlLabel control={<Switch checked={this.isChecked("proprietaire",this.state.method)} value="propriétaire" onChange={event => this.handleChangeSwitch ("proprietaire") }/>} label="proprietaire" />
            <FormControlLabel control={<Switch checked={this.isChecked("selection",this.state.method)} value="sélection" onChange={event => this.handleChangeSwitch ("selection") }/>} label="selection" />
             <FormControlLabel control={<Switch checked={this.isChecked("Territoire",this.state.method)} value="Territoire" onChange={event => this.handleChangeSwitch ("Territoire") }/>} label="Territoire" />
            
            <MenuItem onClick={event =>this.handleClose()}>Valider</MenuItem>
          </Menu>
          */}
          </div>
          

        )}
      </div>
    </Toolbar>
  );
        };
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);



class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'Commune',
    selected: [],
    data: [],
    method:['adherent',/*'proprietaire',*/'selection'/*,'Territoire'*/],
    error: null,
    isLoaded: false,
    page: 0,
    rowsPerPage: 50,
    filtre:'',
    filtreType:'',
    filtreId:'',
    filtreSt:'',
    snackOpen:false,
    snackMessage:'',
    openFormGenWait:false,
  };
  componentDidMount= () => {
    this.dataFill();
  }

  filtrer = (e,t) => {
    switch (t){
      case 'Nom':
        this.setState({filtre:e.toLowerCase()});
        break
      case 'Type':
        this.setState({filtreType:e.toLowerCase()});
        break
      case 'ID':
        this.setState({filtreId:e/*.toLowerCase()*/});
        break
      case 'Statut':
        this.setState({filtreSt:e.toLowerCase()});
        break
      default:
        break
    }
    //console.log(e);
  }

  handleFilter =() => {
    let data =this.state.filtreType.length > 1?
      this.state.data.filter(x=>x['Type'].toLowerCase().includes(this.state.filtreType)):this.state.data;
    
    data =this.state.filtre.length > 2?
      data.filter(x=>x['Nom'].toLowerCase().includes(this.state.filtre)):data;

    data =this.state.filtreId.length > 2?
      data.filter(x=>x['ID'].toString().includes(this.state.filtreId.toString())):data;

      data =this.state.filtreSt.length > 1?
      data.filter(x => x['Statut'] !== null).filter(x=>x['Statut'].toLowerCase().replace("é","e").includes(this.state.filtreSt.replace("é","e"))):data;
    return data;
  }


  dataFill = () =>{
    let urlAppel=process.env.REACT_APP_APIURL+"/ois";
    const {method} = this.state;
    if(method.length !== 0){
      urlAppel=urlAppel+'?filters=['+method.toString()+']&OT='+window.user.OT;
    }
    //console.log(urlAppel);
    fetch(urlAppel,{ 
      method: 'get', 
      headers: new Headers({
          'Authorization': 'Bearer '+ window.user.jeton
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          this.setState({
            isLoaded: true,
            data: result            
          });
          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  snack=(text)=>{
    this.setState({
      snackOpen:true,
      snackMessage:text
    });

  }

  handleSnackClose= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      snackOpen:false,
      snackMessage:''
    });
  }

  mouseOverQuest=(event,id)=>{
    //this.snack(" ID= "+id);
    let urlAppel=process.env.REACT_APP_APIURL+'/form?OIs=['+id+']&OT='+window.user.OT;
    //console.log(event.currentTarget);
    //console.log(this.state);
    //let target=event.currentTarget;
    fetch(urlAppel,{ 
      method: 'get', 
      headers: new Headers({
          'Authorization': 'Bearer '+ window.user.jeton
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          let message='';
          result.state.forEach(element => {
            //console.log(element);
            message+='Questionnaire de ' + element['nom']+'('+element['ID_OT']+') du ' +element['Date'] + ' est ' + element['Statut'] +'\n';
          });
          //this.snack(message)
          alert(message);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.snack("echec recupération des questionnaires "+error)
          //return "echec recupération des questionnaires "+error;
        }
      )
  }

  mouseLeaveQuest=()=>{
    this.setState({
      snackOpen:false,
      snackMessage:''
    });
  }

  formGen = (dateExpire) =>{
    //console.log(this.state.selected);
    this.setState({openFormGenWait:true});
    let urlAppel=process.env.REACT_APP_APIURL+"/formGen";
    const {selected} = this.state;
    if(selected.length !== 0){
      urlAppel=urlAppel+'?OT='+window.user.OT+'&EXP='+dateExpire /*+'&token='+this.props.access_token*/;
    }
    //console.log(urlAppel);
    fetchWithTimeout(urlAppel,{ 
      method: 'post',
      timeout: 8000,
      headers: new Headers({
          'Authorization': 'Bearer '+ window.user.jeton,
          'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ 'Ois' : selected })
    })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          this.setState({ selected: [],openFormGenWait:false });
          if(result.status.error){
            this.snack("Traitement interrompu : "+result.status.error);  
          }else{
            this.snack("Traitement terminé")  
          }
          
          this.dataFill();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //console.log(error);
          if (/timed out/i.test(error) || /abort/i.test(error) ){
            this.snack("Génération toujours en cours en arrière plan, actualisez la page dans quelques instants.");
          } else {
            this.snack("Génération echouée "+error);
          }
          
          this.setState({ openFormGenWait:false });
        }
      )
  }

  formUpdate = () =>{
    //console.log(this.state.selected);
    let urlAppel=process.env.REACT_APP_APIURL+"/formUpdate";
    const {selected} = this.state;
    if(selected.length !== 0){
      urlAppel=urlAppel+'?OIs=['+selected.toString()+']&OT='+window.user.OT;
    }
    //console.log(urlAppel);
    fetch(urlAppel,{ 
      method: 'get', 
      headers: new Headers({
          'Authorization': 'Bearer '+ window.user.jeton
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          this.setState({ selected: [] });
          this.snack("Traitement terminé");  
          this.dataFill();
          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.snack("Génération echouée "+error)
        }
      )
  }
  DelOIs = () =>{
    //console.log(this.state.selected);
    let urlAppel=process.env.REACT_APP_APIURL+"/delOis";
    const {selected} = this.state;
    if(selected.length !== 0){
      urlAppel=urlAppel+'?OT='+window.user.OT;
    }
    //console.log(urlAppel);
    fetch(urlAppel,{ 
      method: 'post', 
      headers: new Headers({
          'Authorization': 'Bearer '+ window.user.jeton,
          'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ 'Ois' : selected })
    })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
          this.setState({ selected: [] });
          this.snack("Traitement terminé");  
          this.dataFill();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.snack("Génération echouée "+error)
        }
      )
  }

  methodChange = (newMethod) => {
    //console.log(newMethod);
    this.setState({method:newMethod}, ()=>{ this.dataFill()} );
    //console.log(this.state.method);
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    //console.log(orderBy);
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      let data =this.handleFilter();
      this.setState(state => ({ selected: data.map(n => n.ID) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  viewObj = (event, id) => {
    var win = window.open('https://base.apidae-tourisme.com/gerer/objet-touristique/'+id+'/consulter/' , '_blank');
    win.focus();

  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    //console.log(this.props.access_token);
    const { classes } = this.props;
    //const { data, order, orderBy, selected, rowsPerPage, page, method } = this.state;
    const { order, orderBy, selected, rowsPerPage, page, method } = this.state;
    const ot=window.user.OT;
    //console.log(ot);
    const data = this.handleFilter();
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const { error, isLoaded,openFormGenWait} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
    return (
      <Paper className={classes.root}>
        <Dialog open={openFormGenWait} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Veuillez patienter</DialogTitle>
          <DialogContent>
            <div className={classes.mycircle}>
              <CircularProgress  className={classes.colorSecondary} />
            </div>
          </DialogContent>
        </Dialog>
        <EnhancedTableToolbar 
          numSelected={selected.length}
          Selected={selected}  
          method={method} 
          methodChange={this.methodChange} 
          formUpdate={this.formUpdate} 
          formGen={this.formGen}
          DelOIs={this.DelOIs}/>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.snackOpen}
            onClose={this.handleSnackClose}
            autoHideDuration={6000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.snackMessage}</span>}
          />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              filtrer={this.filtrer}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.ID);
                  return (
                    <TableRow
                      hover
                      //onClick={event => this.handleClick(event, n.ID)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.ID}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox" >
                        <Checkbox checked={isSelected} onClick={event => this.handleClick(event, n.ID)} className={classes.checkbox} color='default' classes={{checked:classes.checked}} />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none" style={{cursor:'pointer'}} className={classes.colIDApidae} onClick={event => this.viewObj(event, n.ID)}>
                        {n.ID}
                      </TableCell>
                      <TableCell align="left">{n.Type}</TableCell>
                      <TableCell align="left" >{n.Nom}</TableCell>
                      <TableCell align="left" padding="none">{n.Commune}</TableCell>
                      <TableCell align="left">{n.Proprietaire_Nom ===null ? n.Proprietaire_Apidae: n.Proprietaire_Nom}</TableCell>
                      <TableCell align="right">{n.Statut_Apidae}</TableCell>
                      { /*<TableCell align="center" padding="none">{ (n.Adherent) ? "oui":""}</TableCell>
                      <TableCell align="center" padding="none">{ (n.Selection) ? "oui":""}</TableCell>
                      <TableCell align="right">{ (n.Territoire)? "oui":""}</TableCell>
                      */}
                      <TableCell align="right" onClick={event => this.mouseOverQuest(event, n.ID)}> 
                       { //<p onMouseEnter={event=>this.mouseOverQuest(event,n.ID)} onMouseLeave={this.mouseLeaveQuest}>
                       }
                          {n.Statut} {(parseInt(n.ID_OT_Questionnaire)===parseInt(ot) || n.ID_OT_Questionnaire===null )? null : "("+n.ID_OT_Questionnaire+")" }
                      {//</p>
                      }
                      </TableCell>
                      <TableCell align="right">{n.Expire}</TableCell>
                      <TableCell align="right">{(n.Date_Questionnaire===null)?n.Date_Import:n.Date_Questionnaire.slice(0,10)}</TableCell>

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
    }
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);