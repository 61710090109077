import React from 'react';
import { BrowserRouter as Router, Route, Switch,Redirect/*, useParams*/} from "react-router-dom";
import queryString from 'query-string';
//import CircularProgress from '@material-ui/core/CircularProgress';
import './App.css';
import AppBar from './AppBar';


class App extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      Processing:true,
      logout:false      
    }
  }

  logout =()=>{
    this.setState({
      logout:true
    })
  }
  tablRedirect =()=>{
    //console.log('redirect');
    //console.log (date.getTime());
    //console.log (this.state);
    //console.log(window.user.jeton);
    if(window.user !==null || window.user.jeton!=="123"){
      return(
      <Redirect
        to={{
          pathname: "/",
          //search: "?ot="+this.state.OT,
          //state: { referrer: currentLocation }
        }}
      />
  )} 


  }

  Home= () =>{
    if(this.state.logout){
      return(
        <Redirect
          to={{
            pathname: "/logout",
          }}
        />
    )
    }
    if(window.user ===null || window.user.jeton==="123" ){
      //console.log('Auth');
      return(
      <Redirect
        to={{
          pathname: "/auth",
        }}
      />
  )} else {
    //console.log('home');
    //console.log (date.getTime());
    //console.log (this.state);
    //console.log(window.user);

    return (
      <div>
          {/* <CircularProgress/> */}
          <AppBar logout={this.logout}/>
      </div>
    );
  } 
  }

  computeToken = (props) =>{
    window.user=queryString.parse(props.location.search);

    if(window.user !==null || window.user.jeton!=="123"){
      return(
      <Redirect
        to={{
          pathname: "/",
        }}
      />
  )} 
  }
  //basename={'/appli'}
  render(){
    //console.log(process.env.REACT_APP_BASENAME);
    return (
      <Router basename={ process.env.REACT_APP_BASENAME } >
          <Switch>
            <Route exact path="/auth" component={() => {
                window.location = process.env.REACT_APP_APIURL+"/hello";
                return null}
                }/>
            <Route path="/token" component={this.computeToken} />

            <Route exact path="/logout" render={(props)=>
            <div>
              <h2>
                Vous êtes maintenant déconnectés
              </h2>
            </div>  
          }
          />
            <Route exact path="/" component={this.Home} />
          </Switch>
      </Router>
    );
  }

}

export default App;
